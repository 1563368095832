/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotoklub"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"ao9wdpod2pc"} style={{"paddingTop":14,"marginBottom":0,"paddingBottom":21.734375}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Title className="title-box ff--3" content={"Klubové akce"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"b35gaafzfg5"} style={{"paddingTop":10,"paddingBottom":13.383346557617188}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--3 fs--26" content={"Amerika 2022 - jaro<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"rwy0ngf3a2"} style={{"paddingTop":7}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/665046fc69984409b8d33207726a39b3_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/665046fc69984409b8d33207726a39b3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/665046fc69984409b8d33207726a39b3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/665046fc69984409b8d33207726a39b3_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/b4a2454421de40f68906e57e722bcdac_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/b4a2454421de40f68906e57e722bcdac_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/b4a2454421de40f68906e57e722bcdac_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/63a289ae0dac4d2ba9c9b4640708f546_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/63a289ae0dac4d2ba9c9b4640708f546_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/63a289ae0dac4d2ba9c9b4640708f546_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/63a289ae0dac4d2ba9c9b4640708f546_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/97b8e51df48a4cc6b1505f6c4909e959_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/97b8e51df48a4cc6b1505f6c4909e959_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/97b8e51df48a4cc6b1505f6c4909e959_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/97b8e51df48a4cc6b1505f6c4909e959_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":18.050003051757812}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/39174c65dbea4458acb9d35d0d03277c_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/39174c65dbea4458acb9d35d0d03277c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/39174c65dbea4458acb9d35d0d03277c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/39174c65dbea4458acb9d35d0d03277c_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/473b97e6e947497e9c464198a2950127_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/473b97e6e947497e9c464198a2950127_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/473b97e6e947497e9c464198a2950127_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/8305aa94bdf6446998465739e36be86c_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/8305aa94bdf6446998465739e36be86c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/8305aa94bdf6446998465739e36be86c_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/86f7d8a823674af8a6e4708f59c9daeb_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/86f7d8a823674af8a6e4708f59c9daeb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/86f7d8a823674af8a6e4708f59c9daeb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/86f7d8a823674af8a6e4708f59c9daeb_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":18.050003051757812}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/a6b8bf010d4344358a78357d3ee56cd6_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/a6b8bf010d4344358a78357d3ee56cd6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/a6b8bf010d4344358a78357d3ee56cd6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/a6b8bf010d4344358a78357d3ee56cd6_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/b8a7a733c28c4265a8a2a96b4e2ff766_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/b8a7a733c28c4265a8a2a96b4e2ff766_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/b8a7a733c28c4265a8a2a96b4e2ff766_s=660x_.jpg 660w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d64cc404186f4674ad9593543cbd439a_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d64cc404186f4674ad9593543cbd439a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d64cc404186f4674ad9593543cbd439a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d64cc404186f4674ad9593543cbd439a_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/9da459b89e4a46e983f99b085cefdf79_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/9da459b89e4a46e983f99b085cefdf79_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/9da459b89e4a46e983f99b085cefdf79_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/9da459b89e4a46e983f99b085cefdf79_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"b35gaafzfg5"} style={{"paddingTop":10,"paddingBottom":13.383346557617188}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--left ff--3 fs--26" content={"Čierna Voda 2021<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"rwy0ngf3a2"} style={{"paddingTop":7}} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/e72a35e5b7384a298902678874458252_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/e72a35e5b7384a298902678874458252_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e72a35e5b7384a298902678874458252_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/e72a35e5b7384a298902678874458252_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/e72a35e5b7384a298902678874458252_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/e72a35e5b7384a298902678874458252_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/ae192f7e714a461cb7052ef5871bbe9d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/ae192f7e714a461cb7052ef5871bbe9d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ae192f7e714a461cb7052ef5871bbe9d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ae192f7e714a461cb7052ef5871bbe9d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/ae192f7e714a461cb7052ef5871bbe9d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/ae192f7e714a461cb7052ef5871bbe9d_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/e58c4d1e0305459ca3790c2cc1c5afbe_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/e58c4d1e0305459ca3790c2cc1c5afbe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/e58c4d1e0305459ca3790c2cc1c5afbe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/e58c4d1e0305459ca3790c2cc1c5afbe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/e58c4d1e0305459ca3790c2cc1c5afbe_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/e58c4d1e0305459ca3790c2cc1c5afbe_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/1690694ce1a843fb91228a294c68b466_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/1690694ce1a843fb91228a294c68b466_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/1690694ce1a843fb91228a294c68b466_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/1690694ce1a843fb91228a294c68b466_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/1690694ce1a843fb91228a294c68b466_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":18.050003051757812}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/b4d2e2cc7c12459da9cffff8cd127d5a_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/b4d2e2cc7c12459da9cffff8cd127d5a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/b4d2e2cc7c12459da9cffff8cd127d5a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/b4d2e2cc7c12459da9cffff8cd127d5a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/b4d2e2cc7c12459da9cffff8cd127d5a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/b4d2e2cc7c12459da9cffff8cd127d5a_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/fbfd421d021c4630a4561cb6ec8d25b7_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/fbfd421d021c4630a4561cb6ec8d25b7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/fbfd421d021c4630a4561cb6ec8d25b7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/fbfd421d021c4630a4561cb6ec8d25b7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/fbfd421d021c4630a4561cb6ec8d25b7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/fbfd421d021c4630a4561cb6ec8d25b7_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/330a48749e2148e8a54348652163c638_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/330a48749e2148e8a54348652163c638_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/330a48749e2148e8a54348652163c638_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/330a48749e2148e8a54348652163c638_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/330a48749e2148e8a54348652163c638_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/330a48749e2148e8a54348652163c638_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/f642c18c3d754ac1827675696842e2fe_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/f642c18c3d754ac1827675696842e2fe_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/f642c18c3d754ac1827675696842e2fe_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/f642c18c3d754ac1827675696842e2fe_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/f642c18c3d754ac1827675696842e2fe_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/f642c18c3d754ac1827675696842e2fe_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center" style={{"marginTop":18.050003051757812}}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/120e8ea0fcec48d288560e872366c3d8_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/120e8ea0fcec48d288560e872366c3d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/120e8ea0fcec48d288560e872366c3d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/120e8ea0fcec48d288560e872366c3d8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/120e8ea0fcec48d288560e872366c3d8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/120e8ea0fcec48d288560e872366c3d8_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/d9e22a53e684441b9157384b1248dd16_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/d9e22a53e684441b9157384b1248dd16_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/d9e22a53e684441b9157384b1248dd16_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/d9e22a53e684441b9157384b1248dd16_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/d9e22a53e684441b9157384b1248dd16_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/d9e22a53e684441b9157384b1248dd16_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/c559a89ac2264791a397a57a4638fae9_s=350x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/c559a89ac2264791a397a57a4638fae9_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/13078/cef3f2984b284b18bebcab8c6acb925a_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/13078/cef3f2984b284b18bebcab8c6acb925a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/cef3f2984b284b18bebcab8c6acb925a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/cef3f2984b284b18bebcab8c6acb925a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/cef3f2984b284b18bebcab8c6acb925a_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/13078/cef3f2984b284b18bebcab8c6acb925a_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}